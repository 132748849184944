import {FilterList, FilterListItem} from "react-admin";
import LocationIcon from "@mui/icons-material/LocationOn";
import React from "react";

export function RegionFilter() {
    const regionList = ['Atlanta', 'Boston', 'Charlotte', 'Chicago', 'Cincinnati', 'Dallas', 'Denver', 'Detroit', 'Houston', 'Kansas City', 'Los Angeles', 'Memphis', 'New York', 'Orlando', 'Philadelphia', 'Phoenix', 'Pittsburgh', 'San Francisco', 'Seattle', 'Twin Cities', 'Washington DC']

    const regionIsSelected = (value: any, filters: any) => {
        const regions = filters.regions || [];
        return regions.includes(value.region);
    };

    const regionToggleFilter = (value: any, filters: any) => {
        const regions = filters.regions || [];
        return {
            ...filters,
            regions: regions.includes(value.region) ? regions.filter((v: any) => v !== value.region) : [...regions, value.region],
        }
    }

    return (
        <FilterList label="Regions" icon={<LocationIcon/>}>
            {regionList.map((region) => {
                return (
                    <FilterListItem key={region} label={region} value={{region: region}}
                                    isSelected={regionIsSelected} toggleFilter={regionToggleFilter}
                    />
                );
            })}
        </FilterList>
    )
}