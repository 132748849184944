import {useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {Button, Show, SimpleShowLayout, TextField, Toolbar, UrlField} from "react-admin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Divider} from "@mui/material";
import {dealerEnrollmentFields} from "./EnrollmentReport";

export const EnrollmentSingleView = () => {
    const backToEnrollmentPage = useNavigate()
    const handleClose = useCallback(() => {
        backToEnrollmentPage('/enrollments');
    }, [backToEnrollmentPage])

    const ShowActionToolBar = () => (
        <Toolbar>
            <Button onClick={handleClose} color="primary" label="Back">
                <ArrowBackIcon/></Button>
        </Toolbar>
    );

    return (
        <Show actions={ShowActionToolBar()}>
            <SimpleShowLayout sx={{
                fontSize: '1.5em',
                color: 'black',
                '& .RaLabeled-label': {color: '#003478'}
            }} divider={<Divider flexItem/>}>
                {
                    dealerEnrollmentFields.map(({source, label, isUrlField}) => {
                        if (isUrlField) return <UrlField key={source} source={source}/>
                        else if (label === null) return <TextField key={source} source={source}/>
                        else return <TextField key={source} source={source} label={label}/>
                    })
                }
            </SimpleShowLayout>
        </Show>
    );
};