import * as React from "react";
import {Card, CardContent, CardHeader} from "@mui/material";
import {useMsal} from "@azure/msal-react";

export const LogoutPage = () => {

    const { instance } = useMsal();

    const HandleLogout = () => {
// Perform logout logic here
        instance.logoutRedirect();
    };

    return (
        <div>
            <Card>
                <CardHeader title="Logout" />
                <CardContent>
                    <h1>Click Below to Logout and be Redirected to the Login</h1>
                    <button onClick={HandleLogout}>Logout</button>
                </CardContent>
            </Card>

        </div>
    );
};