import React from "react";
import {RepresentativeResponse} from "./RepresentativeUpload";

export function RepresentativeResponses(props: { representativeResponseList: Array<RepresentativeResponse> }) {
    return <>
        <table border={1}>
            <thead>
            <tr>
                <th>Region</th>
                <th>Representatives</th>
                <th>P&A Codes</th>
            </tr>
            </thead>
            <tbody>
            {props.representativeResponseList.map(
                (representativeResponse: RepresentativeResponse) => {
                    return (
                        <tr key={representativeResponse.region}>
                            <td>{representativeResponse.region}</td>
                            <td>
                                {representativeResponse.listOfEmailAddresses.map(
                                    (email) => (
                                        <span key={email}>
                                            {email}
                                            <br/>
                                        </span>
                                    )
                                )}
                            </td>
                            <td>
                                {representativeResponse.listOfVehicleOrderSummaries
                                    .map((it) => {
                                        return it.paCode
                                    }).join(", ")}
                            </td>
                        </tr>
                    );
                }
            )}
            </tbody>
        </table>
    </>;
}