import React, {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import "./css/index.css";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Navigate} from "react-router-dom";
import {Route, Routes} from "react-router";
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {AuthWrapper} from "./components/auth/AuthWrapper";
import {EnrollmentReportBeta} from "./components/enrollmentReportBeta/EnrollmentReportBeta";

const root = createRoot(document.getElementById("root") as HTMLElement);

const configuration = {
    auth: {
        clientId: "61451a96-dd11-4df8-87b5-62216761c8d7",
        authority:
            "https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0",
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT,
    },
    cache: {
        temporaryCacheLocation: "memoryStorage",
        cacheLocation: "memoryStorage",
        storeAuthStateInCookie: true,
        secureCookies: true,
    },
};

const pca = new PublicClientApplication(configuration);

root.render(
    <StrictMode>
        <MsalProvider instance={pca}>
            <BrowserRouter>
                <Routes>
                    <Route path={"/*"} element={<AuthWrapper/>}/>
                    <Route path={"/displayEnrollments"} element={<Navigate to={"/enrollments"}/>}/>
                    <Route path={"/testReport"} element={<EnrollmentReportBeta/>}/>
                </Routes>
            </BrowserRouter>
        </MsalProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
