import {AuthProvider} from "./AuthContext";
import {AuthenticatedTemplate} from "@azure/msal-react";
import {AdminPage} from "../adminPage/AdminPage";

export function AuthWrapper() {
    return(
        <AuthProvider>
            <AuthenticatedTemplate>
                <AdminPage></AdminPage>
            </AuthenticatedTemplate>
        </AuthProvider>
    )
}