import React from "react";
import {EnrollmentsResponse} from "./EnrollmentUpload";

export function EnrollmentResponses(props: { enrollmentsResponseList: Array<EnrollmentsResponse> }) {
    return <>
        <table border={1}>
            <thead>
            <tr>
                <th>Enrollment ID</th>
                <th>Email</th>
                <th>Updated Milestone</th>
            </tr>
            </thead>
            <tbody>
            {props.enrollmentsResponseList?.map((enrollmentResponse: EnrollmentsResponse) => {
                return (
                    <tr key={enrollmentResponse.enrollmentOpId}>
                        <td>{enrollmentResponse.enrollmentOpId}</td>
                        <td>{enrollmentResponse.emailAddress}</td>
                        <td>{enrollmentResponse.updatedMilestone}</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    </>;
}