import React, {ChangeEvent, FormEvent, useState} from "react";
import {getHttpClient} from "../auth/API";
import {WrapNotificationsResponses} from "./WrapNotificationsResponses";

export interface WrapNotificationsResponse {
  enrollmentOpId: string;
  codePa: string;
  vin: string;
  email: string;
  franchise: string;
  vehicleDecalDealershipName: string;
  vehicleDecalWebsite: string;
  vehicleDecalPhoneNumber: string;
}

export function WrapNotifications() {
  const [file, setFile] = useState<File>();
  const [wrapNotificationsList, setWrapNotificationsList] = useState<
    Array<WrapNotificationsResponse>
  >([]);
  const [header, setHeader] = useState("");

  function getWrapNotificationDetails() {
    const formData = new FormData();
    formData.append("file", file!!);

    getHttpClient()
      .post("/decal-details", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.status === 200) {
          setHeader(
            `${response.data.length} EnrollmentOpId(s) to Email a Wrap Verification to:`
          );
          setWrapNotificationsList(response.data);
        } else {
          setHeader("Backend Call Failed");
        }
      });
  }

  function resetResponseLists() {
    setWrapNotificationsList([]);
  }

  const uploadFile = (event: FormEvent) => {
    setHeader("Uploading Launch Enrollment File...");
    resetResponseLists();

    event.preventDefault();

    getWrapNotificationDetails();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setHeader("");
      resetResponseLists();
    }
  };

  function sendWrapNotifications() {
    setHeader("Sending Emails...");

    getHttpClient()
      .post("/decal-notifications", wrapNotificationsList)
      .then((response) => {
        if (response.status === 200) {
          setHeader("Emails Sent");
          setWrapNotificationsList([]);
        } else {
          setHeader("Backend Failed to Send Emails");
        }
      });
  }

  return (
    <div>
      <form onSubmit={uploadFile}>
        <label htmlFor={"wrapNotifications"}>
          Please upload your Launch Enrollment File to send Wrap Verification
          Emails to Dealers:
        </label>
        <br />
        <input
          id="wrapNotifications"
          type={"file"}
          onChange={handleFileChange}
        />

        <button type={"submit"}>Upload</button>
      </form>

      <h3>{header}</h3>
      {wrapNotificationsList?.length > 0 && (
        <>
          <button onClick={sendWrapNotifications}>Send Emails</button>
          <WrapNotificationsResponses
            wrapNotificationsResponseList={wrapNotificationsList}
          />
        </>
      )}
    </div>
  );
}
