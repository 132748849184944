import {EnrollmentUpload} from "./enrollmentUpload/EnrollmentUpload";
import {RepresentativeUpload} from "./representativeUpload/RepresentativeUpload";

export function NotificationDistribution() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "10px",
        }}
      >
        <EnrollmentUpload />
        <RepresentativeUpload />
      </div>
    </>
  );
}
