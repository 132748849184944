import {FilterList, FilterListItem} from "react-admin";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";

export function LaunchStatusFilter() {
    const launchStatusIsSelected = (value: any, filters: any) => {
        const launchStatuses = filters.launchStatuses || [];
        return launchStatuses.includes(value.completedLaunchDate);
    };

    const launchStatusToggleFilter = (value: any, filters: any) => {
        const launchStatuses = filters.launchStatuses || [];
        return {
            ...filters,
            launchStatuses: launchStatuses.includes(value.completedLaunchDate) ? launchStatuses.filter((v: any) => v !== value.completedLaunchDate) : [value.completedLaunchDate],
        }
    }

    return (
        <FilterList label="Launch Status" icon={<CheckCircleIcon/>}>
            <div>
                <FilterListItem label="Launched" value={{completedLaunchDate: 'Launched'}}
                                isSelected={launchStatusIsSelected}
                                toggleFilter={launchStatusToggleFilter}/>
            </div>
            <FilterListItem label="Not Launched" value={{completedLaunchDate: 'Not Launched'}}
                            isSelected={launchStatusIsSelected} toggleFilter={launchStatusToggleFilter}/>
        </FilterList>
    )
}