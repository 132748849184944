import React, {useEffect, useState} from "react";
import {Admin, defaultTheme, Resource} from "react-admin";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CustomDataProvider from "./CustomDataProvider";
import {MyLayout} from "./MyLayout";
import {Enrollment} from "./enrollmentReport/EnrollmentReport";
import {NotificationDistribution} from "../NotificationDistribution";
import UploadIcon from '@mui/icons-material/Upload';
import {WrapNotifications} from "../wrapNotifcations/WrapNotifications";
import NotificationsIcon from '@mui/icons-material/Notifications';
import RateIcon from '@mui/icons-material/RateReview';
import {Feedback} from "./Feedback";
import {LogoutPage} from "./Logout";
import LogOutIcon from '@mui/icons-material/Logout';
import ErrorIcon from '@mui/icons-material/Error';
import {EnrollmentSingleView} from "./enrollmentReport/EnrollmentSingleView";

const myTheme = {
    ...defaultTheme,
    palette: {
        secondary: {
            main: "#020957",
        },
    },
};

export function AdminPage() {
    const [userPermission, setUserPermission] = useState("")

    useEffect(() => {
        setTimeout(()=>{
            const permission = sessionStorage.getItem("userPermission")
            if (permission !== null) {
                setUserPermission(permission)
            }
        }, 1000);
    }, [userPermission])

    return (
        <>
            {userPermission === "UNAUTHORIZED" ?
                <div
                    style={
                        {
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                            height: "100vh",
                            alignItems: "center",
                            fontSize: "2rem",
                            textAlign: "center",
                            flexDirection: "column"
                        }
                    }>
                    <ErrorIcon style={
                        {
                            fontSize: "10rem",
                            color: "red"
                        }
                    }/>
                    <div style={{display:"flex"}}>
                        You don't have permission to access this page. Contact
                        &nbsp;<a href={"mailto:jrau13@ford.com"}>Jason Rau (jrau13)</a> &nbsp;
                        to get access.
                    </div>
                </div>
                :
                <Admin
                    theme={myTheme}
                    dataProvider={CustomDataProvider}
                    layout={MyLayout}
                    loginPage={false}
                    disableTelemetry={true}
                >

                    <Resource
                        name="enrollments"
                        options={{label: "Enrollments Report"}}
                        list={Enrollment}
                        show={EnrollmentSingleView}
                        icon={AirportShuttleIcon}
                        recordRepresentation="name"
                    />

                    {userPermission === "ADMIN" && <Resource
                        name="uploads"
                        list={NotificationDistribution}
                        icon={UploadIcon}
                    />}

                    {userPermission === "ADMIN" && <Resource
                        name="decalnotifications"
                        options={{label: "Decal Notifications"}}
                        list={WrapNotifications}
                        icon={NotificationsIcon}
                    />}


                    <Resource
                        name="feedback"
                        options={{label: "Feedback"}}
                        list={Feedback}
                        icon={RateIcon}
                    />

                    <Resource
                        name="logout"
                        options={{label: "Logout"}}
                        list={LogoutPage}
                        icon={LogOutIcon}
                    />
                </Admin>
            }
        </>
    );
}
