import {DataProvider, fetchUtils} from "react-admin";
import jsonServerProvider from "ra-data-json-server";


const apiUrl = window.location.origin.includes("localhost") ? window.location.origin + "/api" : "https://api.pd01e.gcp.ford.com/msccy"

const httpClient = (url: any, options: fetchUtils.Options | undefined) => {
  options = {
    headers: new Headers({
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("encodedAccessToken")}`,
    }),
  };
  return fetchUtils.fetchJson(url, options);
};

const CustomDataProvider: DataProvider = jsonServerProvider(apiUrl, httpClient);

export default CustomDataProvider;
