import {DealerEnrollmentField, dealerEnrollmentFields} from "../adminPage/enrollmentReport/EnrollmentReport";
import {useEffect, useState} from "react";
import {DealerEnrollment} from "./DealerEnrollment";
import {getHttpClient} from "../auth/API";
import {ColumnButton} from "./ColumnButton/ColumnButton";

export function EnrollmentReportBeta() {
    const [dealerEnrollments, setDealerEnrollments] = useState<DealerEnrollment[]>(new Array<DealerEnrollment>())
    const [dealerEnrollmentsField, setDealerEnrollmentsField ] = useState(dealerEnrollmentFields)

    useEffect(() => {
        getHttpClient().get("/enrollments").then((response) => {
            setDealerEnrollments(response.data)
        })
    }, [])

    useEffect(() => {
        const selectedColumnsString = localStorage.getItem("selectedColumns")
        if(selectedColumnsString != null){
            const items: string[] = JSON.parse(selectedColumnsString)
           const updatedDealerEnrollmentFields =  dealerEnrollmentFields.map(field => {
                const newField: DealerEnrollmentField = {...field, isSelected: items.includes(field.label)}
                return newField
            })
            setDealerEnrollmentsField(updatedDealerEnrollmentFields)
        }
    }, []);

    return (
        <>
            <ColumnButton dealerEnrollmentsField ={dealerEnrollmentsField} setDealerEnrollmentsField={setDealerEnrollmentsField}/>
            <table>
                <thead>
                <tr>
                    {dealerEnrollmentsField.filter(({isSelected})=>isSelected).map(({label}) => {
                            return (
                                <th key={label}>{label}</th>
                            )
                    })}
                </tr>
                </thead>
                <tbody>
                {dealerEnrollments && dealerEnrollments.length > 0 && dealerEnrollments.map((dealerEnrollment) => {
                    return (
                        <tr key={dealerEnrollment.id}>
                            <td key={dealerEnrollment.regionName}>{dealerEnrollment.regionName}</td>
                            <td key={dealerEnrollment.regionCode}>{dealerEnrollment.regionCode}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    )
}