import {FilterList, FilterListItem} from "react-admin";
import FlagIcon from "@mui/icons-material/Flag";
import {Tooltip} from "@mui/material";
import React from "react";

export function ProductionStatusFilter() {
    const statusToggleFilter = (value: any, filters: any) => {
        const statuses = filters.statuses || [];
        return {
            ...filters,
            statuses: statuses.includes(value.status) ? statuses.filter((v: any) => v !== value.status) : [...statuses, value.status],
        }
    }

    const statusIsSelected = (value: any, filters: any) => {
        const statuses = filters.statuses || [];
        return statuses.includes(value.status);
    };

    return (
        <FilterList label="Production Status" icon={<FlagIcon/>}>
            <Tooltip
                title={"Current Target Delivery Date OR Estimated Delivery Date is farther in the future than the previous version of the file."}>
                <div>
                    <FilterListItem label="Production Delay" value={{status: 'Production Delay'}}
                                    isSelected={statusIsSelected}
                                    toggleFilter={statusToggleFilter}/>
                </div>
            </Tooltip>
            <FilterListItem label="On Schedule" value={{status: 'On Schedule'}}
                            isSelected={statusIsSelected} toggleFilter={statusToggleFilter}/>
        </FilterList>
    )
}