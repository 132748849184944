import {
    DatagridConfigurable,
    ExportButton,
    List,
    Pagination,
    SelectColumnsButton,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    UrlField
} from "react-admin";
import {InputAdornment} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React, {useEffect, useRef, useState} from "react";
import {getHttpClient} from "../../auth/API";
import {FilterSidebar} from "./filterSidebar/FilterSidebar";


export interface FileUploadInfo {
    id: number;
    uploadDateTime: number;
}


export const Enrollment = () => {
    const isCalledEnrollmentAnalytics = useRef(false)
    const [uploadTime, setUploadTime] = useState<FileUploadInfo>()

    function convertToDate(date: number | undefined) {
        if (date == null) {
            return new Date()
        }

        const epochMilliseconds = date * 1000
        var epochToDate = new Date(epochMilliseconds)

        return epochToDate.toDateString()
    }

    useEffect(() => {
        if (!isCalledEnrollmentAnalytics.current) {
            getHttpClient()
                .get("/fileUploadInfo")
                .then((response) => {
                    setUploadTime(response.data)
                })
                .catch((err) => {

                })
            isCalledEnrollmentAnalytics.current = true
            getHttpClient().post("/enrollment-analytics")

        }
        ;
    }, []);

    const postFilters = [
        <TextInput
            label="Search VIN, Dealer, Region or P&A Code"
            source="searchInput"
            alwaysOn
            resettable
            style={{width: 500}}
            InputProps={{
                endAdornment: (
                    <InputAdornment position={"end"}>
                        <SearchIcon/>
                    </InputAdornment>
                )
            }}
        />
    ];


    function createToggleButton(innerText: string, id: string) {
        const toggleButton = document.createElement('button')
        toggleButton.innerText = innerText
        toggleButton.style.cssText = toggleButtonCss
        toggleButton.id = id
        return toggleButton
    }

    function createDeselectButton() {
        const button = createToggleButton('Deselect All', 'deselectButton')
        button.onclick = () => {
            localStorage.setItem('RaStore.preferences.enrollments.datagrid.columns', "[]")
            forceRefresh(Math.random())
        }
        return button
    }

    function createSelectButton() {
        const button = createToggleButton('Select All', 'selectAllButton')
        button.onclick = () => {
            localStorage.removeItem('RaStore.preferences.enrollments.datagrid.omit')
            localStorage.removeItem('RaStore.preferences.enrollments.datagrid.columns')
            forceRefresh(Math.random())
        }
        return button
    }

    const addColumnToggleButtons = () => {
        setTimeout(() => {
            const columnDropdown = document.getElementsByClassName("MuiBox-root css-3w1zuh")
            if (columnDropdown.length === 1) {
                if (document.getElementById('deselectButton') === null)
                    columnDropdown[0].prepend(createDeselectButton())
                if (document.getElementById('selectAllButton') === null)
                    columnDropdown[0].prepend(createSelectButton())

            }
        }, 300);
    }

    const [refresh, forceRefresh] = useState(Math.random)
    useEffect(() => {
    }, [refresh])


    return (
        <div>
            <List
                aside={<FilterSidebar/>}
                actions={<TopToolbar/>}
                pagination={<Pagination style={{marginRight: "auto"}}/>}
                filters={postFilters}
                sx={{
                    flexGrow: 1,
                    marginRight: '300px',
                }}
            >
                <b> Enrollment Report Last Updated: &nbsp; </b>
                <>
                    {uploadTime != null && convertToDate(uploadTime?.uploadDateTime)}
                </>

                <Toolbar>
                    <div onClick={addColumnToggleButtons}>
                        <SelectColumnsButton/>
                    </div>
                    <ExportButton maxResults={5000}/>
                </Toolbar>
                <DatagridConfigurable
                    bulkActionButtons={false}
                    rowClick="show"
                    sx={{
                        '& .RaDatagrid-headerCell': {
                            fontWeight: "bolder",
                            fontSize: "1.0em",
                        }
                    }}
                    omit={dealerEnrollmentFields.filter(({isSelected}) => !isSelected).map(({source}) => {return source})}
                >
                    {
                        dealerEnrollmentFields.map(({source, label, isUrlField}) => {
                            if (isUrlField) return <UrlField key={source} source={source}/>
                            else return <TextField key={source} source={source} label={label}/>
                        })
                    }
                </DatagridConfigurable>
            </List>
        </div>
    );
};

export interface DealerEnrollmentField{
    source: string;
    label: string;
    isSelected: boolean;
    isUrlField: boolean;
}



export const dealerEnrollmentFields: DealerEnrollmentField[] = [
    {source: "status", label: "Production Status", isSelected: true, isUrlField: false},
    {source: "id", label: "Enrollment Op ID", isSelected: true, isUrlField: false},
    {source: "msUsaProgram", isSelected: true, label: "MS USA Program", isUrlField: false},
    {source: "fcsdMarketAreaName", label: "FCSD Market Area", isSelected: false, isUrlField: false},
    {source: "mktFacingRegion", label: "MKT Facing Region", isSelected: false, isUrlField: false},
    {source: "regionName", label: "Region Name", isSelected: false, isUrlField: false},
    {source: "cvcRegionCity", label: "CVC Region City", isSelected: false, isUrlField: false},
    {source: "regionCode", label: "Region Code", isSelected: false, isUrlField: false},
    {source: "region", isSelected: true, label: "Region", isUrlField: false},
    {source: "zone", label: "Zone", isSelected: false, isUrlField: false},
    {source: "originalEnrollDealer", label: "Original Enroll Dealer", isSelected: false, isUrlField: false},
    {source: "dealerName", label: "Dealer Name", isSelected: false, isUrlField: false},
    {source: "codeDealer", label: "Sales Code", isSelected: true, isUrlField: false},
    {source: "codePa", label: "P&A Code", isSelected: true, isUrlField: false},
    {source: "msTrainer", isSelected: true, label: "MS Trainer", isUrlField: false},
    {source: "program", isSelected: true, label: "Program", isUrlField: false},
    {source: "earnback", isSelected: true, label: "Earnback", isUrlField: false},
    {source: "enrollmentDate", isSelected: true, label: "Enrollment Date", isUrlField: false},
    {source: "cbm", label: "CBM", isSelected: true, isUrlField: false},
    {source: "franchise", isSelected: true, label: "Franchise",  isUrlField: false},
    {source: "chassisOrderFrnchsColor", label: "Chassis Order Frnchs Color", isSelected: false, isUrlField: false},
    {source: "vin", label: "VIN", isSelected: false, isUrlField: false},
    {source: "requestedChassis", isSelected: true, label: "Requested Chassis",  isUrlField: false},
    {source: "configuration", isSelected: true, label: "Configuration",  isUrlField: false},
    {source: "upfitter", isSelected: true, label: "Upfitter", isUrlField: false},
    {source: "myFromVin", label: "My From VIN", isSelected: false, isUrlField: false},
    {source: "fortyFiveDayLaunchPrep", isSelected: true, label: "Forty Five Day Launch Prep", isUrlField: false},
    {source: "targetDeliveryDate", isSelected: true, label: "Target Delivery Date", isUrlField: false},
    {source: "estimatedDeliveredToDealer", isSelected: true, label: "Estimated Delivered To Dealer", isUrlField: false},
    {source: "finalDeliveryDateLt", isSelected: true , label: "Final Delivery Date Lt", isUrlField: false},
    {source: "upfitterWorkEstimatedEventDateLt", label: "Upfitter Work Estimated Event Date Lt", isSelected: false, isUrlField: false},
    {source: "upfitterCompoundExitEventDateLt", label: "Upfitter Compound Exit Event Date Lt", isSelected: false, isUrlField: false},
    {source: "vinFreightVerifyUrl", isUrlField: true, label: "VIN Freight Verify Url", isSelected: false},
    {source: "lastMilestoneEventDateLt", label: "Last Milestone Event Date Lt", isSelected: false, isUrlField: false},
    {source: "currentFvcEventDateLt", label: "Current FVC Event Date Lt", isSelected: false, isUrlField: false},
    {source: "vehicleLocation", label: "Vehicle Location", isSelected: false, isUrlField: false},
    {source: "sdsKitEstShipDate", label: "SDS Kit Est Ship Date", isSelected: false, isUrlField: false},
    {source: "sdsKitTrackingNum", label: "SDS Kit Tracking Num", isSelected: false, isUrlField: false},
    {source: "sdsKitActualShipDate", label: "SDS Kit Actual Ship Date", isSelected: false, isUrlField: false},
    {source: "escapeVomelaShipDate", label: "Escape Vomela Ship Date", isSelected: false, isUrlField: false},
    {source: "escapeVomelaEstDlvrDate", label: "Escape Vomela Est Dlvr Date", isSelected: false, isUrlField: false},
    {source: "escapeVomelaTrckngNum", label: "Escape Vomela Trckng Num", isSelected: false, isUrlField: false},
    {source: "escapeVomelaEstInstlDate", label: "Escape Vomela Est Instl Date", isSelected: false, isUrlField: false},
    {source: "escapeVomelaActInstlDate", label: "Escape Vomela Act Instl Date", isSelected: false, isUrlField: false},
    {source: "dateToolsOrdered", label: "Date Tools Ordered", isSelected: false, isUrlField: false},
    {source: "invoiceDate", label: "Invoice Date", isSelected: false, isUrlField: false},
    {source: "toolsPo", label: "Tools PO", isSelected: false, isUrlField: false},
    {source: "trackingNumber", label: "Tracking Number", isSelected: false, isUrlField: false},
    {source: "toolDeliveredDate", isSelected: true, label: "Tool Delivered Date", isUrlField: false},
    {source: "shortage", label: "Shortage", isSelected: false, isUrlField: false},
    {source: "etaToDealer", label: "ETA To Dealer", isSelected: false, isUrlField: false},
    {source: "runDate", label: "Run Date", isSelected: false, isUrlField: false},
    {source: "introToMsMeetingDate", label: "Intro To MS Meeting Date", isSelected: false, isUrlField: false},
    {source: "msKickoffMeetingDate", label: "MS Kickoff Meeting Date", isSelected: false, isUrlField: false},
    {source: "actualUnitArrivalDate", label: "Actual Unit Arrival Date", isSelected: false, isUrlField: false},
    {source: "dealerLaunchReadyYN", label: "Dealer Launch Ready YN", isSelected: false, isUrlField: false},
    {source: "dealerNotLaunchReadyTechYN", label: "Dealer Not Launch Ready Tech YN", isSelected: false, isUrlField: false},
    {source: "dealerNotLaunchReadyCoordinatorYN", label: "Dealer Not Launch Ready Coordinator YN", isSelected: false, isUrlField: false},
    {source: "dealerNotLaunchReadyDealerEngagedYN", label: "Dealer Not Launch Ready Dealer Engaged YN", isSelected: false, isUrlField: false},
    {source: "scheduledLaunchDate", isSelected: true, label: "Scheduled Launch Date", isUrlField: false},
    {source: "completedLaunchDate", isSelected: true, label: "Completed Launch Date", isUrlField: false},
    {source: "eliteEnrollmentFlag", label: "Elite Enrollment Flag", isSelected: false, isUrlField: false},
    {source: "programLeadPhoneNumber", label: "Program Lead Phone Number", isSelected: false, isUrlField: false},
    {source: "programLeadEmail", isSelected: true, label: "Program Lead Email", isUrlField: false},
    {source: "programLeadName", label: "Program Lead Name", isSelected: false, isUrlField: false},
    {source: "mobtecTrainedCount", label: "Mobtec Trained Count", isSelected: false, isUrlField: false},
    {source: "vehicleDecalDealershipName", label: "Vehicle Decal Dealership Name", isSelected: false, isUrlField: false},
    {source: "vehicleDecalPhoneNumber", label: "Vehicle Decal Phone Number", isSelected: false, isUrlField: false},
    {source: "vehicleDecalWebsite", label: "Vehicle Decal Website", isSelected: false, isUrlField: false},
    {source: "cellPhoneInterfaceSystem", label: "Cell Phone Interface System (IEP)", isSelected: false, isUrlField: false},
    {source: "sellingDlrDate", label: "Selling Dlr Date", isSelected: false, isUrlField: false},
]

const toggleButtonCss = "height: 40px;\n" +
    "padding: 12px 16px;\n" +
    "justify-content: center;\n" +
    "align-items: center;\n" +
    "gap: 0px;\n" +
    "margin-bottom: 10px;\n" +
    "border-radius: 400px;\n" +
    "background: #066FEF;\n" +
    "border: 0;\n" +
    "color: #FFF;\n" +
    "text-align: center;\n" +
    "font-size: 15px;\n" +
    "font-weight: bold;\n" +
    "margin-left: 43px;"