import {Card, CardContent, CardHeader} from "@mui/material";


export const Feedback = () => (
    <Card>
        <CardHeader title="Feedback" />
        <CardContent>
            If you have any feedback to continuously improve upon this product, please let us know <a
            href="https://forms.office.com/r/i9EydZbJMg">here.</a>
        </CardContent>
    </Card>
);