import {useMsal, useMsalAuthentication,} from "@azure/msal-react";
import { InteractionType, RedirectRequest} from "@azure/msal-browser";
import {useEffect, useState} from "react";
import {AuthInfo} from "./AuthContext";
import {getHttpClient} from "./API";
import {decodeTokenAndSaveCdsidToSessionStorage} from "../../utils/JwtPayloadDecoder";

export function useAuthHelper() {
    const {instance} = useMsal();
    const [authInfo, setAuthInfo] = useState<AuthInfo>({
        result: undefined, login: undefined, fetchWithAuth: fetchWithAuth
    });

    const account = instance.getActiveAccount();

    const request : RedirectRequest = {
        scopes: ["api://61451a96-dd11-4df8-87b5-62216761c8d7/Test.Scope"],
        // @ts-expect-error account type expects undefined, not null, ignore for now
        account,
    };
    const {login, result} = useMsalAuthentication(
        InteractionType.Redirect,
        {...request}
    );

    function fetchWithAuth(path : string, userOptions = {method: "GET"}) {
        const headers = new Headers();
        const bearer = `Bearer ${result?.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("content-type", "application/json")

        const options = {
            ...userOptions,
            headers,
        };
        return fetch(path, options);
    }

    function setToken(tokenKey: string, token: string) {
        sessionStorage.setItem(tokenKey, token+"")
    }

    useEffect(() => {
        setAuthInfo({ login, result, fetchWithAuth });
        if (result !== undefined && result !== null) {
            let token = result.accessToken;
            let tokenKey = "encodedAccessToken";
            setToken(tokenKey, token);
            decodeTokenAndSaveCdsidToSessionStorage(token)
            getHttpClient().get("/permissions").then((response) => {
                sessionStorage.setItem("userPermission", response.data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);

    return authInfo;
}