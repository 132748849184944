import React from "react";
import {FailedNotificationDetails} from "./EnrollmentUpload";

export function FailedEnrollmentResponses(props: { failedEmailList: Array<FailedNotificationDetails> }) {
    return <>
        <h3>{`${props.failedEmailList.length} Email(s) Failed to Send:`}</h3>
        <table border={1}>
            <thead>
            <tr>
                <th>P&A Code</th>
                <th>Email Address</th>
            </tr>
            </thead>
            <tbody>
            {props.failedEmailList?.map((failedEmail: FailedNotificationDetails) => {
                return (
                    <tr key={failedEmail.codePa}>
                        <td>{failedEmail.codePa}</td>
                        <td>{failedEmail.emailAddress}</td>
                    </tr>
                );
            })}
            </tbody>

        </table>
    </>
}