import React, {ChangeEvent, FormEvent, useState} from "react";
import {getHttpClient} from "../auth/API";
import {FailedEnrollmentResponses} from "./FailedEnrollmentResponses";
import {EnrollmentResponses} from "./EnrollmentResponses";

export interface EnrollmentsResponse {
    enrollmentOpId: string;
    codePa: string;
    emailAddress: string;
    updatedMilestone: string;
}

export interface FailedNotificationDetails {
    codePa: string;
    emailAddress: string;
}

export function EnrollmentUpload() {
    const [file, setFile] = useState<File>();
    const [enrollmentsResponseList, setEnrollmentsResponseList] = useState<Array<EnrollmentsResponse>>([]);
    const [failedEmailList, setFailedEmailList] = useState<Array<FailedNotificationDetails>>([]);
    const [header, setHeader] = useState("");

    function uploadEnrollment() {
        const formData = new FormData();
        formData.append("file", file!!);

        getHttpClient()
            .post("/enrollments", formData, {headers: {"Content-Type": "multipart/form-data",},})
            .then((response) => {
                if (response.status === 200) {
                    setHeader(
                        `${response.data.length} EnrollmentOpId(s) to Email an Update to:`
                    );
                    setEnrollmentsResponseList(response.data);
                } else {
                    setHeader("Backend Call Failed");
                }
            });
    }

    function resetResponseLists() {
        setEnrollmentsResponseList([]);
        setFailedEmailList([]);
    }

    const uploadFile = (event: FormEvent) => {
        setHeader("Uploading Launch Enrollment File...");
        resetResponseLists();

        event.preventDefault();

        uploadEnrollment();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
            setHeader("");
            resetResponseLists();
        }
    };

    function sendEmails() {
        setHeader("Sending Emails...");

        getHttpClient()
            .post("/milestone-notifications", enrollmentsResponseList)
            .then((response) => {
                if (response.status === 200) {
                    setHeader("Emails Sent");
                    setEnrollmentsResponseList([]);
                    setFailedEmailList(response.data);
                } else {
                    setHeader("Backend Failed to Send Emails");
                }
            });
    }


    return (
        <div>
            <form onSubmit={uploadFile}>
                <label htmlFor={"enrollmentUpload"}>
                    Please upload your Launch Enrollment File to send Daily Updates to Dealers:
                </label>
                <br/>
                <input id="enrollmentUpload" type={"file"} onChange={handleFileChange}/>

                <button type={"submit"}>Upload</button>
            </form>

            <h3>{header}</h3>
            {enrollmentsResponseList?.length > 0 &&
                <>
                    <button onClick={sendEmails}>Send Emails</button>
                    <EnrollmentResponses enrollmentsResponseList={enrollmentsResponseList}/>
                </>
            }

            {failedEmailList?.length > 0 && <FailedEnrollmentResponses failedEmailList={failedEmailList}/>}

        </div>
    );
}
