import {Card, CardContent} from "@mui/material";
import {SavedQueriesList} from "react-admin";
import BookmarkIcon from "@mui/icons-material/BookmarkBorder";
import {LaunchStatusFilter} from "./LaunchStatusFilter";
import {ProductionStatusFilter} from "./ProductionStatusFilter";
import {RegionFilter} from "./RegionFilter";
import React from "react";

export function FilterSidebar() {
    return (
        <Card sx={{order: -1, mr: 2, mt: 8, width: 410, maxWidth: 224}}>
            <CardContent sx={{maxWidth: 250}}>
                <div style={{transform: "translate(32px, 42px)", backgroundColor: "white"}}> Saved Views</div>
                <SavedQueriesList icon={<BookmarkIcon/>}/>
                <LaunchStatusFilter/>
                <ProductionStatusFilter/>
                <RegionFilter/>
            </CardContent>
        </Card>
    )
}