import {useState} from "react";
import {DealerEnrollmentField} from "../../adminPage/enrollmentReport/EnrollmentReport";
import "./ColumnButton_styles.css";
import {Switch} from "@mui/material";

export function ColumnButton(props: {
    dealerEnrollmentsField: DealerEnrollmentField[],
    setDealerEnrollmentsField: (input: DealerEnrollmentField[]) => void
}) {
    const [showDropDown, setShowDropDown] = useState(false)

    function handleChange(e: any, label: string) {
        const updatedList: DealerEnrollmentField[] = props.dealerEnrollmentsField.map((field) => {
            if (field.label === label) {
                const newField: DealerEnrollmentField = {
                    source: field.source,
                    label: field.label,
                    isUrlField: field.isUrlField,
                    isSelected: e.target.checked
                }
                return newField
            }
            return field
        })
        const isSelectedTrueLabels = updatedList.filter(({isSelected}) => isSelected).map(field => field.label)
        localStorage.setItem("selectedColumns", JSON.stringify(isSelectedTrueLabels))
        props.setDealerEnrollmentsField(updatedList)
    }

    return (
        <>
            <button onClick={() => setShowDropDown(!showDropDown)} className={"columnButton"}>
                Columns
            </button>

            {showDropDown &&
                <>
                    <div data-testid={"modal-backdrop"}
                         onClick={() => setShowDropDown(false)} className={"backdrop"}></div>
                    <div className="testingClass">
                        <button onClick={() => setShowDropDown(false)}
                                className={"columnXButton"}>
                            X
                        </button>
                        <ul>
                            {props.dealerEnrollmentsField.map((dealerEnrollmentField) => {
                                return (<li key={dealerEnrollmentField.label} className={"list-style"}>
                                    {dealerEnrollmentField.label}
                                    <Switch data-testid={dealerEnrollmentField.label + "-toggle"}
                                            defaultChecked={dealerEnrollmentField.isSelected}
                                            onChange={(e) => {
                                                handleChange(e, dealerEnrollmentField.label)
                                            }}></Switch>
                                </li>)
                            })}
                        </ul>
                    </div>
                </>
            }
        </>

    )
}

