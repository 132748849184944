import React, {ChangeEvent, FormEvent, useState} from "react";
import {getHttpClient} from "../auth/API";
import {AxiosResponse} from "axios";
import {RepresentativeResponses} from "./RepresentativeResponses";

export interface RepresentativeResponse {
    region: string;
    listOfEmailAddresses: Array<string>;
    listOfVehicleOrderSummaries: Array<VehicleOrderSummary>;
}

export interface VehicleOrderSummary {
    enrollmentOpId: String;
    paCode: String;
    vin: String;
    dealershipName: String;
    updatedMilestones: Array<String>;
}

export function RepresentativeUpload() {
    const [header, setHeader] = useState("");
    const [file, setFile] = useState<File>();
    const [representativesResponseList, setRepresentativesResponseList] =
        useState<Array<RepresentativeResponse>>([]);

    function uploadRegionCsv() {
        const formData = new FormData();
        formData.append("file", file!!);

        getHttpClient()
            .post("/representatives", formData, {headers: {"Content-Type": "multipart/form-data",},})
            .then((response: AxiosResponse<Array<RepresentativeResponse>>) => {
                if (response.status === 200) {
                    let regionCount = response.data.length;
                    setHeader(`${regionCount} Region(s) to Email an Update to:`);
                    setRepresentativesResponseList(response.data);
                } else {
                    setHeader("Backend Call Failed");
                }
            });
    }

    const uploadFile = (event: FormEvent) => {
        setHeader("Uploading Region Email Distribution File...");
        setRepresentativesResponseList([]);

        event.preventDefault();

        uploadRegionCsv();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
            setRepresentativesResponseList([]);
            setHeader("");
        }
    };

    function sendRepEmails() {
        setHeader("Sending Representative Emails...");

        getHttpClient()
            .post("/representative-notifications", representativesResponseList)
            .then((response) => {
                if (response.status === 200) {
                    setHeader("Emails Sent");
                    setRepresentativesResponseList([]);
                } else {
                    setHeader("Backend Failed to Send Emails");
                }
            });
    }

    return (
        <div>
            <form onSubmit={uploadFile}>
                <label htmlFor="region-input">
                    Please upload your Region Email Distribution File to send Representative Summaries for the past
                    week:
                </label>
                <br/>
                <input id="region-input" type={"file"} onChange={handleFileChange}/>

                <button type={"submit"}>Upload</button>
            </form>

            <h3>{header}</h3>
            {representativesResponseList?.length > 0 && (
                <>
                    <button onClick={sendRepEmails}>Send Emails</button>
                    <RepresentativeResponses representativeResponseList={representativesResponseList}/>
                </>
            )}
        </div>
    );
}
