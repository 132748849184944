import axios from "axios";

export function getHttpClient() {
  axios.interceptors.request.use((config) => {
    const token = sessionStorage.getItem("encodedAccessToken");

    config.baseURL = window.location.origin.includes("localhost") ? window.location.origin + "/api" : "https://api.pd01e.gcp.ford.com/msccy"

    if (config.headers === undefined) config.headers = {};

    config.headers.Authorization = "Bearer " + token;

    return config;
  });
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      return error;
    }
  );
  return axios;
}