import React from "react";
import {WrapNotificationsResponse} from "./WrapNotifications";

export function WrapNotificationsResponses(props: {
  wrapNotificationsResponseList: Array<WrapNotificationsResponse>;
}) {
  return (
    <>
      <table border={1}>
        <thead>
          <tr>
            <th>Enrollment ID</th>
            <th>Email</th>
            <th>VIN</th>
          </tr>
        </thead>
        <tbody>
          {props.wrapNotificationsResponseList?.map(
            (wrapNotificationsResponse: WrapNotificationsResponse) => {
              return (
                <tr key={wrapNotificationsResponse.enrollmentOpId}>
                  <td>{wrapNotificationsResponse.enrollmentOpId}</td>
                  <td>{wrapNotificationsResponse.email}</td>
                  <td>{wrapNotificationsResponse.vin}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}
