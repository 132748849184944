import * as React from "react";
import {AppBar, TitlePortal} from "react-admin";
import Box from "@mui/material/Box";

import mobileServiceLogo from "../../data/mobile_service_logo.jpg";
import {useMediaQuery} from "@mui/material";

export const MyAppBar = () => {
  const isSmall = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));

  return (
    <AppBar userMenu={false}>
      {isSmall ? "" : <TitlePortal />}

      <img style={{maxHeight: "2em", paddingLeft:"10vw"}} src={mobileServiceLogo} alt="Mobile Service Logo" />

      <Box flex="1" />
        Hello, {sessionStorage.getItem("cdsid")}
    </AppBar>
  );
};
