import React, {createContext} from 'react';
import {useAuthHelper} from "./AuthHelper";

export interface AuthInfo {
    result: any;
    login: (() => void) | undefined;
    fetchWithAuth: ((path: string, userOptions?: any) => Promise<Response>);
}

export const AuthContext = createContext<AuthInfo | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const auth = useAuthHelper(); // Assuming useAuthHelper is your custom hook
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};